.Popup-overlay {

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10;

  background: #0000005b;

  display: flex;

  justify-content: center;
  align-items: center;
}


.Popup {
  max-width: 70%;
  max-height: 70%;
  background: #FFF;

  padding: 20px;
  /* margin: 10px; */

  border-radius: 30px;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  position: relative;
}

.Popup > h1 {
  text-align: center;
}

.Popup > .Popup-close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #000;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.Popup > .Popup-close:hover {
  background: #636363;
}


.Popup .Popup-content {
  padding: 0 10px;
  overflow-y: auto;
}


.Popup * {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.6) rgba(0, 0, 0, 0.3);
}

.Popup ::-webkit-scrollbar {
  width: 5px;
}

.Popup ::-webkit-scrollbar-track {
  background: rgb(182, 182, 182);
  overflow: visible;
}

.Popup ::-webkit-scrollbar-thumb {
  background: #636363;
  border-radius: 10px;
}

.Popup ::-webkit-scrollbar-thumb:hover {
  background: #000;

}