.QuizQuestionCount {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-bottom: 150px;
}
.QuizQuestionCount-content {
  transition: all 0.5s;

  width: 100%;
  display: block;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.35), transparent);
  font-weight: 400;
  color: #FFF;
  font-size:calc(12px + 1.5vw); /* fallback */
  font-size: min(5vw, 20pt);
  text-align: center;
  padding: 5px 30px;


  justify-self: center;


  /* border: 1px solid lime; */
}

.QuizQuestionCount span {
  display: block;
  font-weight: 700;
  font-size: 40pt;
}