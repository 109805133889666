.ScreenForm {
  height: 100%;
  display: flex;
  flex-direction: column;

}
.ScreenForm .ScreenForm-content{
  flex-grow: 1;

  max-width: 800px;
  margin: 0 auto;

  padding-top: 40px;


  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow-y: auto;


}
.ScreenForm .ScreenForm-content form {
  padding: 0 20px;
  flex-grow: 1;

  margin: 30px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid lime; */
}
.ScreenForm .ScreenForm-content form > div{
  display: flex;

  justify-content: center;
  gap: 10px;
}

.ScreenForm .ScreenForm-content input[type="text"],
.ScreenForm .ScreenForm-content input[type="email"]{
  display: block;
  margin-bottom: 20px;
  font-size: 1em;
  padding: 10px;
  min-width: 0;
  width: 100%;
  border-radius: 10px;
  border: none;

  font-family: "Roboto";
  font-weight: 300;


  border: 3px solid #FFF;

  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

.ScreenForm .ScreenForm-content input[type="checkbox"] {
  background: #fff;
  border: none;
  width: 15px;
  height: 15px;
  vertical-align: middle;
}


.ScreenForm .ScreenForm-content form .mentions-legales {
  display: block;
  font-size: 0.8em;
  color: #FFF;
}
.ScreenForm .ScreenForm-content form .mentions-legales a{
  color: #FFF;
}

.ScreenForm .ScreenForm-content input:focus {
  outline: solid var(--succubus-purple-text);
}

.ScreenForm .ScreenForm-content input:not(:focus):valid {
  border-color: var(--succubus-green);

  background: url('../../images/form_check.png') #FFF no-repeat right 10px center;
  background-size: 25px;
}

.ScreenForm .ScreenForm-content input:not(:placeholder-shown):not(:focus):invalid {
  border-color: var(--succubus-red);

  background: url('../../images/form_warning.png') #FFF no-repeat right 10px center;
  background-size: 25px;
}

.ScreenForm .hero{
  display: block;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.35), transparent);
  font-weight: 300;
  color: #FFF;
  font-size:calc(12px + 1.5vw); /* fallback */
  font-size: min(5vw, 20pt);
  text-align: center;
  padding: 5px 30px;

}

.ScreenForm button {
  background: rgba(255, 255, 255, 0.473) url('../../images/form_btn_arrow.png') right 30px center no-repeat;

  background-size: 15px;
  border: none;
  margin: 0 auto;
  display: block;
  padding: 15px 50px;
  padding-right: 65px;

  border-radius: 999px;

  color: #FFF;
  font-weight: 500;

  font-size:calc(14px + 1.5vw);
  font-size: min(5vw, 20pt);

  cursor: pointer;
}


.ScreenForm button.valid { background-color: #11e2d1; }
.ScreenForm button:hover { box-shadow: 0 0 20px rgba(255, 255, 255, 0.418);}
