.ScreenEnd {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ScreenEnd .ScreenEnd-content {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 60px ;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  flex-grow: 1;
  overflow-x: visible;
  overflow-y: auto;

  position: relative;
}

.ScreenEnd .ScreenEnd-content-bottom {

  display: flex;
  flex-direction: column;
  justify-content: center;

  /* background: url("../../images/JC_home.png") no-repeat bottom left; */
  /* background-size: contain; */

  flex-grow: 1;
}

.ScreenEnd .hero{
  display: block;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.35), transparent);
  font-weight: 400;
  color: #FFF;
  font-size:calc(12px + 1.5vw); /* fallback */
  font-size: min(5vw, 20pt);
  text-align: center;
  padding: 5px 30px;

  margin-bottom: 10px;

  white-space: pre-wrap;

}

.ScreenEnd .message{
  text-align: center;
  color: #FFF;
  /* padding-left: 30%; */
}
.ScreenEnd .message a{
  display: block;
  font-size: 25pt;
  color: #FFF;
  font-weight: 700;
}