* {box-sizing: border-box;}

:root {
  --succubus-green: #00c9b7;
  --succubus-red: #ff715e;
  --succubus-purple-text: #3d1c90;
}

html, body, #root, .App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  font-family: 'Roboto', sans-serif;

  user-select: none;
}


.App * {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.6) rgba(255, 255, 255, 0.3);
}

.App ::-webkit-scrollbar {
  width: 5px;
}

.App ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.3);
  overflow: visible;
}

.App ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}

.App ::-webkit-scrollbar-thumb:hover {
  background: #FFF;

}





body { background: linear-gradient(135deg, #6740c9, #ff6f61); }

img {
  max-width: 100%;
}
button {
  font-size: 1em;
  font-family: inherit;
}

.App {
  display: flex;
  flex-direction: column;

  /* max-width: 800px; */
  position: relative;

  margin: 0 auto;

  /* background: #FFF; */
  /* background: linear-gradient(135deg, #6740c9, #ff6f61); */


}

.version {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 0.8em;
}
.debug {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 0.8em;
  color: #FFF;
  background: red;

}


.screen {
  flex-grow: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}
.screen .screen-content {
  flex-grow: 1;
}


button.link-btn {

  background: none;
  border: none;
  padding: 0;
  color: darkblue;
  cursor: pointer;
}
button.link-btn:hover {
  text-decoration: underline;
}


.error {
  color: darkred;
}