.QuizAnswers {
  padding: 30px;
  display: flex;
  flex-direction: column;

  gap: 20px;

  overflow-x: hidden;

}

.QuizAnswers .choice{

  width: 100%;
  border: 5px solid transparent;
  font-size: 17pt;

  font-size: min(6vw, 18pt);

  color: var(--succubus-purple-text);

  padding: 7px 2vw;

  font-weight: 500;
  border-radius: 999px;
  background: none;

  background: #FFF;
}

.QuizAnswers .choice.red{
  background: var(--succubus-red);
  color: #FFF;
  border-color: #ffb1a7;


  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);

}
.QuizAnswers .choice.green{
  background: var(--succubus-green);
  border-color: #4dffef;
  color: #FFF;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

/* .choice {
  opacity: 0;
  transform: translateX(-500px);
}
.choice.enter-active,
.choice.enter-done {
  opacity: 1;
  transform: translateX(0px);
  transition: all 200ms;

} */