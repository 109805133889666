.Personnage {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: transparent;
  pointer-events: none;

  background: rgba(0, 0, 0, 0.30);

  transition: opacity 350ms;

}

.Personnage.hidden { opacity: 0; }

.Personnage.visible { opacity: 1; }

.Personnage .SpeechDisplayer { transition: transform 180ms; }
.Personnage.hidden .SpeechDisplayer { transform: translateX(-100px);}
.Personnage.visible .SpeechDisplayer { transform: translateX(0);}



/** DIAL ************************************/

.Personnage .dial-overlay {
  pointer-events: auto;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;



}

.Personnage .dial {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFF;
  color: #9c199f;
  text-align: center;
  padding: 20px;

  font-size: 18pt;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.30);

  margin: 30px;

  border-radius: 20px;

  user-select: none;
}
.Personnage .dial-character {

  display: inline-block;
  /* border: 1px solid  red; */
  font-size: 28pt;
  font-weight: bold;
  padding: 3px 25px;
  background: #E3E7E8;
  color: var(--almost-black);
  position: relative;
  text-transform: uppercase;
  margin-bottom: -1px;


  display: none;
}
.Personnage .dial-character:after {
  /* content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  aspect-ratio: 1 / 1;


  z-index: -1;
  background: red;
  background: #E3E7E8;

  transform: skewX(45deg) translateX(-50%); */


}


.Personnage .dial-text {
  /* padding: 20px 25px;

  background: #E3E7E8;
  color: var(--almost-black);
  font-size: 22pt;
  pointer-events: auto; */
}





.Personnage .dial-loader {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
}
.Personnage .dial-loader .loader{
  width: 100%;
  height: 100%;
  border-color: var(--maincolor);
}