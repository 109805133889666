.Baseline {

  max-width: 800px;
  position: absolute;
  right: 0;
  left: 0px;
  bottom: 5px;
  padding-right: 10px;



  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  color: #FFF;
  font-size: 7pt;


}

.Baseline .spacer {
  flex-grow: 1;
}

.Baseline > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
}

.Baseline img{ height: 20px; }
.Baseline a{ color: #FFF; white-space: nowrap ;}
.Baseline span{ line-height: 10px; }
