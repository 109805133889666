.Header {
  position: relative;
  margin-bottom: 30px;
  background: #6740c9;

}



.Header-content{
  max-width: 800px;
  min-height: 50px;

  /* border: 1px solid  red; */
  position: relative;
  margin: 0 auto;

  padding: 25px;
  padding-bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 3;

}
.Header-content img{
  /* max-width: 100%; */
  width: 100%;
  max-width: 500px;
}
.Header::before,
.Header::after {
  content: "";
  position: absolute;
  height: 60px;
  left: 0;
  width: 100%;
  top: 100%;
  background: #6740c9;
  transform: skewY(-1deg) translateY(-20px);
  z-index: 2;

  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.3);

}
.Header::after { height: 40px; }


.Header .spacer {
  flex-grow: 1;
}
.Header .language-selector {
  margin: 0 10px;
}