.QuizDebrief{
  font-size: 16pt;
  font-size: min(5vw, 16pt);

  padding: 30px;
  text-align: center;
}
.QuizDebrief .correct{
  padding: 30px;
  background: #f1ffed;
  color: var(--succubus-green);
  border-radius: 20px;
  border: 5px solid  var(--succubus-green);

}
.QuizDebrief .incorrect{
  padding: 30px;
  background: #f1ffed;
  color: var(--succubus-red);
  border-radius: 20px;
  border: 5px solid  var(--succubus-red);
}

.QuizDebrief button {

  font-size: 18pt;
  border: none;
  background: var(--succubus-green);
  color: #FFF;
  padding: 10px 40px;
  border-radius: 999px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
  margin-top: 20px;

  cursor: pointer;

  position: relative;

}

.QuizDebrief button::after {
  content: "";
  width: 14px;
  height: 14px;
  border: 4px solid #FFF;
  border-top: none;
  border-left: none;
  display: inline-block;

  transform: translateY(-2px) translateX(10px) rotate(-45deg) ;
}


.QuizDebrief button:hover { background: #11e2d1; }
.QuizDebrief button:active { background: var(--succubus-green); }