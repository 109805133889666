.StarsHeader{
  display: flex;
  justify-content: center;
}
.StarsHeader .Star{
  margin: 10px;
  fill: #3a198c;

  width: 25%;

}
.StarsHeader .Star.lit{
  fill: #fbf679;

  filter: drop-shadow(0 0 10px rgba(251, 247, 121, 0.7));
}