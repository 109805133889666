.ScreenHome {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;


}
.ScreenHome .ScreenHome-content {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 40px ;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex-grow: 1;
  overflow-x: visible;
  overflow-y: auto;
}

.ScreenHome .hero strong{
  display: block;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.644), transparent);
  text-transform: uppercase;
  font-weight: 700;
  color: #FFF;
  font-size:calc(12px + 1.5vw); /* fallback */
  font-size: min(5vw, 20pt);

  text-align: center;
  padding: 5px 30px;
  font-weight: 700;
  line-height: 1.8em;
}
.ScreenHome .hero p {
  text-align: center;
  padding: 0 10%;
  font-size: 14pt;
  color: #FFF;
  font-weight: 300;
}



.ScreenHome .ScreenHome-content-bottom {

  flex-grow: 1;

  display: flex;
  align-items: flex-end;

  position: relative;

  /* background: url("../../images/JC_home.png") no-repeat bottom left;
  background-size: contain; */
}


.ScreenHome .button-container {
  align-self: stretch;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  justify-content: center;

  padding: 0 10px;
  margin-left: 30%;
}


.ScreenHome .button-container button {
  background: var(--succubus-green);
  border: none;
  margin: 0 auto;
  display: block;
  padding: 25px 90px;
  padding: 15px 50px;

  border-radius: 999px;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 700;
  font-size:calc(14px + 1.5vw);
  font-size: min(6vw, 30pt);

  box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.336);

  cursor: pointer;
}

.ScreenHome .button-container button:hover { background: #11e2d1; }
.ScreenHome .button-container button:active { background: var(--succubus-green); }

.ScreenHome .button-container a.mentions-legales {
  display: block;
  margin-top: 24px;
  color: #FFF;
  /* text-decoration: none; */
  text-align: center;
  font-weight: 300;
}
.ScreenHome .Personnage{
  background: transparent;
  z-index: -1;
}



@media screen and (max-width: 600px) {
  .ScreenHome-content .hero p {

    padding-left: 25%;
  }
}