.QuizImage{
  background: #EFEFEF;
  position: relative;

  border: 3px solid  #00c9b7;

  margin: 0 auto;
  max-width: 90%;
  max-height: 300px;

  margin-bottom: 20px;

  border-radius: 15px;
  overflow: hidden;


  /* ratio 800x534 */

  aspect-ratio: 800/534;

}

.QuizImage img{
  /* max-height: 200px; */
  opacity: 0;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: block;

  transition: opacity 0.5s;
}

.QuizImage.loaded img{ opacity: 100%;}

.QuizImage .image-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%)  translateY(-50%);

  /* border: 1px solid  red; */
}