.Confettis {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

.Confettis canvas{
  width: 100%;
  height: 100%;

}