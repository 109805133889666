.QuizDuration {
  /* border: 2px solid  #FFF; */
  max-width: 80%;
  margin: 0 auto;
  border-radius: 99px;
  overflow: hidden;
  height: 24px;
  background: #FFF;

  color: #FFF
}

.QuizDuration .QuizDuration-bar{
  height: 100%;
  text-align: right;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  background: var(--succubus-green);
  transition: width 0.1s linear;

  border-radius: 0 99px 99px 0;
}
.QuizDuration .QuizDuration-bar span{
  font-weight: 500;
  margin-right: 15px;
}
.QuizDuration .QuizDuration-bar.urgent{
  background: var(--succubus-red);
}