.ScreenQuiz {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ScreenQuiz .quiz-header {
  text-align: center;
  color: #FFF;
  max-width: 100%;


}

.ScreenQuiz .quiz-header .quiz-count {
  margin-bottom: 4%;
  font-size: min(5vw, 14pt);
}
.ScreenQuiz .quiz-header .quiz-count span{
  display: block;
  font-size: 20pt;
  font-weight: 500;
  font-size: min(8vw, 20pt);
}
.ScreenQuiz .quiz-header .question_text{
  font-size: 22pt;
  font-size: min(8vw, 22pt);

  font-weight: 500;
  max-width: 100%;
  text-align: center;
  word-wrap: break-word;
}
.ScreenQuiz .ScreenQuiz-content {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px ;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex-grow: 1;

  overflow-y: auto;
}
